<template>
  <div class="follow-page view-page">
    <!-- 搜素 -->
    <c-search searchTitle="关键词搜索" valueFormat="yyyyMMdd" @search="clientSearch" placeholder="姓名/电话" :isBorder="isBorder" :isDate="true"></c-search>
    <c-navigation @change="caseCategory" title="客户类别" :list="clientCategory" isBorder></c-navigation>

    <!-- 分类 -->
    <div class="classify-1">
      <c-navigation v-if="clientTypeList.length > 0" title="客户分类" @change="getclientType" :list="clientTypeList"></c-navigation>
      <c-navigation title="案件状态" @change="getCaseStatus" :list="alterstatusList"></c-navigation>
    </div>
    <c-navigation v-if="situation.length > 0" title="案件情况" @change="getCaseCondition" :list="situation" :isBorder="true"></c-navigation>

    <!-- 按钮 -->
    <c-operate>
      <template #right>
        <div class="btn-group">
          <div class="btn-list btn-blue" @click="setCourtDate('up')">上传方案</div>
          <div class="btn-list btn-green" @click="setCourtDate('tag')">重点标记</div>
          <div class="btn-list btn-orange" @click="setCourtDate('end')">结束案件</div>
          <div class="btn-list btn-roseRed" @click="setCourtDate('date')">开庭时间</div>
        </div>
      </template>
    </c-operate>
    <!-- 表格 -->
    <c-table :data="tableData">
      <el-table-column align="center" label="选择" width="100">
        <template #default="{ row }">
          <el-checkbox @change="myClientCheckbox(row)"></el-checkbox>
          {{ row.data }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="username" label="联系人"></el-table-column>
      <el-table-column align="center" prop="mobile" label="电话" width="130"></el-table-column>
      <el-table-column align="center" prop="age" label="年龄" width="60"></el-table-column>
      <el-table-column align="center" prop="sexlist" label="性别" width="60"></el-table-column>
      <el-table-column align="center" prop="company" label="公司"></el-table-column>
      <el-table-column align="center" prop="typelist" label="客户分类"></el-table-column>
      <el-table-column align="center" prop="aj_ms_statuslist" label="案件情况"></el-table-column>
      <el-table-column align="center" prop="aj_statuslist" label="案件分类">
        <template #default="{ row }">
          <p>{{ ['民事案件', '刑事案件', '待完善'][row.aj_statuslist] }}</p>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="yw_admin_id" label="业务人员"></el-table-column>
      <el-table-column align="center" prop="startime" label="开庭时间" width="180"></el-table-column>
      <el-table-column align="center" label="重点标记">
        <template #default="{ row }">
          <div class="flag" v-if="row.yw_importance == 1">
            <img :src="$icon('vital')" alt="" />
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="260">
        <template #default="{ row }">
          <div class="table-operate">
            <p class="operate-list edit" @click="checkCase(row)">查看</p>
            <p class="operate-list follow" @click="findOrderInfo(0, row.id)">进度详情</p>
            <p class="operate-list end" @click="setEndCase(row)">确定结束</p>
          </div>
        </template>
      </el-table-column>
    </c-table>

    <!-- 查看案件 -->
    <c-drawer v-model="showCaseDetails" :width="40.5" title="查看案件">
      <div class="newClient">
        <div class="newClientFrom">
          <div class="w-50">
            <p class="name">姓名：</p>
            <span class="list-content">{{ viewTableData.username }}</span>
          </div>
          <div class="w-50">
            <p class="name">联系方式：</p>
            <span class="list-content">{{ viewTableData.username }}</span>
          </div>
          <div class="w-50">
            <p class="name">年龄：</p>
            <span class="list-content">{{ viewTableData.username }}</span>
          </div>
          <div class="w-50">
            <p class="name">性别：</p>
            <span class="list-content">{{ viewTableData.sexlist }}</span>
          </div>
          <div class="w-50">
            <p class="name">客户分类：</p>
            <span class="list-content">{{ viewTableData.typelist }}</span>
          </div>
          <div class="w-50">
            <p class="name">起诉/应诉：</p>
            <span class="list-content">{{ viewTableData.lawsuitlist }}</span>
          </div>
          <div class="w-50">
            <p class="name">客户来源：</p>
            <span class="list-content">{{ viewTableData.sourcelist }}</span>
          </div>
          <div class="w-80">
            <p class="name">客户地址：</p>
            <span class="list-content">{{ viewTableData.addr }}</span>
          </div>
          <div class="w-80">
            <p class="name">公司名称：</p>
            <span class="list-content">{{ viewTableData.company }}</span>
          </div>
          <div class="w-80 align-start">
            <p class="name">备注：</p>
            <span class="list-content">{{ viewTableData.renark }}</span>
          </div>
        </div>
        <p class="title">#案件信息</p>
        <div class="newClientFrom">
          <div class="w-50">
            <p class="name">案件类型：</p>
            <span class="list-content">{{ viewTableData.aj_statuslist }}</span>
          </div>
          <div class="w-50">
            <p class="name">标的额：</p>
            <span class="list-content">{{ viewTableData.aj_money }}</span>
          </div>
          <div class="w-50" v-if="viewTableData.aj_statuslist == 0">
            <p class="name">案件情况：</p>
            <span class="list-content">{{ viewTableData.aj_ms_statuslist }}</span>
          </div>
          <div class="w-50" v-if="viewTableData.aj_statuslist == 0">
            <p class="name">案件类型：</p>
            <span class="list-content">{{ viewTableData.aj_ms_typelist }}</span>
          </div>
          <template v-if="viewTableData.aj_statuslist == 1">
            <div class="w-50">
              <p class="name">案件阶段：</p>
              <span class="list-content">{{ viewTableData.aj_xs_statuslist }}</span>
            </div>
            <div class="w-50">
              <p class="name">罪名：</p>
              <span class="list-content">{{ viewTableData.aj_xs_contentlist }}</span>
            </div>
            <div class="w-80">
              <p class="name">委托人与被害人关系：</p>
              <span class="list-content">{{ viewTableData.aj_xs_tylelist }}</span>
            </div>
          </template>
          <div class="w-80">
            <p class="name">案号：</p>
            <span class="list-content">{{ viewTableData.aj_code }}</span>
          </div>
          <div class="w-80">
            <p class="name">案发时间：</p>
            <span class="list-content">{{ viewTableData.aj_date }}</span>
          </div>
          <div class="w-80" v-if="viewTableData.aj_statuslist == 0">
            <p class="name">案由：</p>
            <span class="list-content">{{ viewTableData.aj_ms_contentlist }}</span>
          </div>
          <div class="w-80 align-start">
            <p class="name">备注：</p>
            <span class="list-content">{{ viewTableData.aj_remark }}</span>
          </div>
        </div>
        <p class="title">#原告</p>
        <div class="newClientFrom">
          <div class="w-50">
            <p class="name">姓名：</p>
            <span class="list-content">{{ viewTableData.yg_name }}</span>
          </div>
          <div class="w-50">
            <p class="name">年龄：</p>
            <span class="list-content">{{ viewTableData.yg_age }}</span>
          </div>
          <div class="w-50">
            <p class="name">联系方式：</p>
            <span class="list-content">{{ viewTableData.yg_mobile }}</span>
          </div>
          <div class="w-50">
            <p class="name">性别：</p>
            <span class="list-content">{{ viewTableData.yg_sex }}</span>
          </div>
          <div class="w-80">
            <p class="name">身份证号：</p>
            <span class="list-content">{{ viewTableData.yg_idnum }}</span>
          </div>
          <div class="w-80">
            <p class="name">地区：</p>
            <span class="list-content">{{ viewTableData.yg_area }}</span>
          </div>
          <div class="w-80">
            <p class="name">地址：</p>
            <span class="list-content">{{ viewTableData.yg_addr }}</span>
          </div>
          <div class="w-80 align-start">
            <p class="name">备注：</p>
            <span class="list-content">{{ viewTableData.yg_remark }}</span>
          </div>
        </div>
        <p class="title">#被告</p>
        <div class="newClientFrom">
          <div class="w-50">
            <p class="name">姓名：</p>
            <span class="list-content">{{ viewTableData.bg_name }}</span>
          </div>
          <div class="w-50">
            <p class="name">年龄：</p>
            <span class="list-content">{{ viewTableData.bg_age }}</span>
          </div>
          <div class="w-50">
            <p class="name">联系方式：</p>
            <span class="list-content">{{ viewTableData.bg_mobile }}</span>
          </div>
          <div class="w-50">
            <p class="name">性别：</p>
            <span class="list-content">{{ viewTableData.bg_sex }}</span>
          </div>
          <div class="w-80">
            <p class="name">身份证号：</p>
            <span class="list-content">{{ viewTableData.bg_idnum }}</span>
          </div>
          <div class="w-80">
            <p class="name">地区：</p>
            <span class="list-content">{{ viewTableData.bg_area }}</span>
          </div>
          <div class="w-80">
            <p class="name">地址：</p>
            <span class="list-content">{{ viewTableData.bg_addr }}</span>
          </div>
          <div class="w-80 align-start">
            <p class="name">备注：</p>
            <span class="list-content">{{ viewTableData.bg_remark }}</span>
          </div>
        </div>
      </div>
    </c-drawer>

    <!-- 跟进信息 -->
    <c-drawer v-model="showFollow" :width="35" title="进度信息详情">
      <!-- 按钮切换 -->
      <div class="btn" style="margin-bottom: 30px">
        <div class="follow-up" @click="isfollowUpCheck" :class="{ active: isfollowUp }">跟进详情</div>
        <div class="schedule" @click="isScheduleCheck" :class="{ active: isSchedule }">进度详情</div>
      </div>
      <!-- 跟进详情 -->
      <div v-if="isfollowUp == true">
        <c-time-line :data="timeLine"></c-time-line>
      </div>
      <!-- 进度详情 -->
      <div v-if="isSchedule == true">
        <c-time-line :data="timeLine"></c-time-line>
        <div class="schedule-btn-case">
          <div class="btn-edit" @click="showNewDetails = true">编辑进度</div>
          <!-- <div class="btn-confirm">完成任务</div> -->
        </div>
      </div>
    </c-drawer>

    <!-- 新建跟进 -->
    <c-dialog v-model="showNewDetails" title="新建跟进" :mask="false" @confirm="addScheduling">
      <div class="schedule-case">
        <el-checkbox class="schedule-list" v-for="item in schedulingOptions" size="small" :key="item.id" v-model="item.isChecked" :label="item.name" border></el-checkbox>
      </div>
    </c-dialog>

    <!-- 完成任务 -->
    <c-dialog v-model="showFinishTask" title="完成进度" :mask="false">
      <div class="finishTask">
        <p>备注：</p>
        <el-input type="textarea" :rows="3" placeholder="请输入跟进备注..." v-model="textarea" resize="none"> </el-input>
      </div>
    </c-dialog>

    <!-- 选择开庭时间 -->
    <!-- <c-dialog v-model="selectCourtDate" title="开庭时间" :mask="true">
      <c-date-picker-vue @select="selectedDate"></c-date-picker-vue>
    </c-dialog> -->
    <c-dialog v-model="selectCourtDate" title="开庭时间" :mask="true" @confirm="setTime">
      <div class="selectDate">
        <div class="inpTime">
          <img :src="iconUrl('data')" alt="" />
          <input type="text" placeholder="选择时间" v-model="courtDate" />
        </div>
        <c-date-picker-vue @select="selectedDate"></c-date-picker-vue>
      </div>
    </c-dialog>

    <!-- 结束案件 -->
    <c-dialog v-model="showFinishCase" title="结束案件" :mask="true" @confirm="setEndFinishOrder(1)">
      <div class="finishCase">您确定要结束您所勾选的案件吗？</div>
    </c-dialog>
    <c-dialog v-model="showFinishCase_2" title="结束案件" :mask="true" @confirm="setEndFinishOrder(2)">
      <div class="finishCase">您确定要结束当前案件吗？</div>
    </c-dialog>

    <!-- 重点标记 -->
    <c-dialog v-model="showEmphasisSign" title="重点标记" :mask="true" @confirm="setImportanceCase">
      <div class="finishCase">您确定要重点标记您所勾选的案件吗？</div>
    </c-dialog>

    <!-- 上传 -->
    <c-dialog v-model="showUploading" title="上传方案" :mask="true" @confirm="confirmUploading">
      <c-upload multiple :limit="6" @on-success="onSuccess" @on-remove="onSuccess"></c-upload>
      <div class="upRemark">
        <span>备注：</span>
        <el-input type="textarea" :rows="3" placeholder="请输入备注..." v-model="uploadingParam.remark" resize="none"> </el-input>
      </div>
    </c-dialog>

    <!-- 分页 -->
    <div class="page">
      <c-pagination @change="changePage" :pageSize="listParam.limit" :total="contactsTotal"></c-pagination>
    </div>
  </div>
</template>

<script>
import iconUrl from '@/utils/icon.js'
import CDrawer from '@/components/c-drawer'
import CTimeLine from '@/components/c-time-line'
import CDialog from '@/components/c-dialog'
import cDatePickerVue from '@/components/c-date-picker.vue'
import { getTypeList } from '@/api/common'
import * as caseApi from '@/api/case.js'
import * as clientApi from '@/api/client.js'
import config from "@/utils/config.js";

export default {
  name: 'Follow',
  components: {
    CDrawer,
    CTimeLine,
    CDialog,
    cDatePickerVue
  },
  data() {
    return {
      iconUrl: iconUrl,
      isBorder: true, //是否显示下边框
      showCaseDetails: false, //查看案件详情
      showFollowUpInfoDetails: false,
      isfollowUp: true, // 跟进详情
      isSchedule: false, //进度详情
      addNewTrail: false, //新建跟进
      showFinishTask: false, //完成任务
      selectCourtDate: false, //选择开庭时间
      showFinishCase: false, // 结束案件
      showFinishCase_2: false, //结束案件2
      showEmphasisSign: false, // 重点标记
      showUploading: false, // 上传
      uploadingFile: [],
      uploadingParam: {
        order_id: '',
        url: '',
        remark: ''
      },
      courtDate: '', // 开庭时间
      upRemark: '', // 上传的备注
      clientTypeList: [], // 客户分类
      situation: [], // 案件情况
      contactsTotal: 0, // 总页数
      clientCategory: [
        // 客户类别
        {
          id: 2,
          name: '跟进客户',
          isChecked: true
        },
        {
          id: 3,
          name: '成交客户',
          isChecked: false
        }
      ],
      // 跟进情况
      followList: [
        {
          id: 0,
          isChecked: true,
          name: '加微信'
        },
        {
          id: 1,
          isChecked: false,
          name: '来访'
        },
        {
          id: 2,
          isChecked: false,
          name: '已成交'
        }
      ],

      // 审批事项
      approvalList: [
        {
          id: 1,
          name: '全部',
          isChecked: true
        },
        {
          id: 2,
          name: '未述',
          isChecked: false
        },
        {
          id: 3,
          name: '财产保全',
          isChecked: false
        },
        {
          id: 4,
          name: '一审',
          isChecked: false
        },
        {
          id: 5,
          name: '二审',
          isChecked: false
        },
        {
          id: 6,
          name: '执行',
          isChecked: false
        },
        {
          id: 7,
          name: '终本',
          isChecked: false
        },
        {
          id: 8,
          name: '执恢',
          isChecked: false
        },
        {
          id: 9,
          name: '破产清算',
          isChecked: false
        }
      ],
      // 表格数据
      tableData: [],
      // 假数据
      options: [],
      input: '123',
      // 跟进详情
      timeLine: [],
      textarea: '', // 完成任务备注
      selectedTable: [], // 选中的项
      // 提交的列表数据
      listParam: {
        usmo: '', // 搜索的关键词
        type: 2, // 2:跟进客户 3:成交客户
        alterstatus: 0, // 案件状态:0=进行中,1=已回款,2=已完成,3=暂停,4=搁浅
        typelist: '', //	 客户分类:0=A类,1=B类,2=C类,3=D类
        aj_ms_statuslist: '', // 民事 案件情况:0=未诉,1=财产保全,2=一审,3=二审,4=执行,5=终本,6=执恢,7=破产清算
        starday: '', // 开始天数
        endday: '', //结束天数
        page: 1, // 分页页码，数字类型
        limit: 7 // 一页展示条数
      },
      alterstatusList: [
        // 案件状态
        {
          id: '',
          name: '全部',
          isChecked: true
        },
        {
          id: 0,
          name: '进行中',
          isChecked: false
        },
        {
          id: 1,
          name: '已回款',
          isChecked: false
        },
        {
          id: 2,
          name: '已完成',
          isChecked: false
        },
        {
          id: 3,
          name: '暂停',
          isChecked: false
        },
        {
          id: 4,
          name: '搁浅',
          isChecked: false
        }
      ],
      viewTableData: {},
      showFollow: false,
      showNewDetails: false,
      schedulingOptions: null,
      selectedSchedulingId: '',
      myClientCheckboxArray: [], //选择要设置开庭时间的数据
      datePicker: '', //开庭时间
      endCaseId: '' //结束案件id
    }
  },
  watch: {
    showFollow(newValue) {
      if (!newValue) {
        this.isfollowUp = true
        this.isSchedule = false
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.clientTypeList = await getTypeList(1)
      this.situation = await getTypeList(5)
      this.schedulingOptions = await getTypeList(11)
      this.getList(0, 0, 0)
    },
    // 获取列表
    getList() {
      caseApi.getList(this.listParam).then(res => {
        this.tableData = res.data.data
        this.contactsTotal = res.data.total
      })
    },
    /**
     * 客户类别
     */
    caseCategory(v) {
      this.listParam.type = v.id
      this.getList()
    },
    /**
     * 客户类型
     */
    getclientType(e) {
      this.listParam.typelist = e.id
      this.getList()
    },
    /**
     * 案件状态
     */
    getCaseStatus(e) {
      this.listParam.alterstatus = e.id
      this.getList()
    },
    /**
     * 案件状态
     */
    getCaseCondition(e) {
      this.listParam.aj_ms_statuslist = e.id
      this.getList()
    },
    /**
     * @description: 客户查询
     * @param {*} e: 要查询的条件
     */
    clientSearch(e) {
      this.listParam.usmo = e.keyWord
      if (e.date) {
        this.listParam.starday = e.date[0]
        this.listParam.endday = e.date[1]
      } else {
        this.listParam.starday = ''
        this.listParam.endday = ''
      }
      this.getList()
    },
    // 查看
    checkCase(row) {
      this.showCaseDetails = true
      clientApi.getFind({ id: row.id }).then(res => {
        this.viewTableData = res.data
      })
    },
    /**
     * @description: 查询客户跟进/进度信息
     * @param {*} order_id: 要查询的 ID
     */
    findOrderInfo(type, order_id) {
      if (order_id) {
        this.selectedSchedulingId = order_id
      }
      this.showFollow = true
      const param = {
        type,
        order_id: order_id || this.selectedSchedulingId
      }
      clientApi.findOrderInfo(param).then(res => {
        this.timeLine = res.data
      })
    },
    /**
     * @description: 切换到跟进详情
     */
    isfollowUpCheck() {
      this.isfollowUp = true
      this.isSchedule = false
      this.findOrderInfo(0)
    },
    /**
     * @description: 切换到进度详情
     */
    isScheduleCheck() {
      this.isSchedule = true
      this.isfollowUp = false
      this.findOrderInfo(1)
    },
    /**
     * @description:
     * @return {*}
     */
    /**
     * @description: 添加进度详细
     */
    addScheduling() {
      const param = {
        status: 1,
        order_id: this.selectedSchedulingId,
        images: '',
        remark: '',
        tempo: ''
      }
      const tempo = []

      this.schedulingOptions.forEach(item => {
        if (item.isChecked) {
          tempo.push(item.id)
        }
      })
      param.tempo = tempo.join(',')
      clientApi.addOrderInfo(param).then(res => {
        this._showMsg('添加成功', 'success')
        this.showNewDetails = false
        this.findOrderInfo(1)
      })
    },
    // 跟进消息
    follow(row) {
      this.showFollowUpInfoDetails = true
    },
    // 完成任务
    finishTask() {
      this.showFinishTask = true
    },
    /**
     * @description: 改变我的客户列表选中的值
     * @param {*} v: 改变的值
     */
    myClientCheckbox(v) {
      const i = this.myClientCheckboxArray.indexOf(v.id)
      if (i !== -1) {
        this.myClientCheckboxArray.splice(i, 1)
      } else {
        this.myClientCheckboxArray.push(v.id)
      }
    },
    // 选中的项
    checkedClient(row) {
      let idx
      this.selectedTable.forEach((item, index) => {
        if (item.id === row.id) {
          idx = index
        }
      })
      if (idx >= 0) {
        this.selectedTable.splice(idx, 1)
      } else {
        this.selectedTable.push(row)
      }
    },
    /**
     * 重点标记
     */
    setImportanceCase() {
      const param = {
        type: 1,
        importance: 1,
        order_id: this.myClientCheckboxArray[0]
      }
      caseApi.setImportance(param).then(res => {
        this.showEmphasisSign = false
        this.getList()
        this._showMsg('设置成功', 'success')
      })
    },
    /**
     * 结束案件
     */
    setEndFinishOrder(typeNum) {
      let param = {
        id: ''
      }
      if (typeNum == 1) {
        param.id = this.myClientCheckboxArray[0]
      } else if (typeNum == 2) {
        param.id = this.endCaseId
      }

      caseApi.endFinishOrder(param).then(res => {
        this.showFinishCase = false
        this.showFinishCase_2 = false
        this.getList()
        this._showMsg('案件已结束', 'success')
      })
    },
    /**
     * 结束案件2
     */
    setEndCase(e) {
      this.showFinishCase_2 = true
      this.endCaseId = e.id
    },

    /**
     * @description: 上传方案/设置开庭时间/重点标记的相关判断
     * @param {*} type: 类型
     */
    setCourtDate(type) {
      if (this.myClientCheckboxArray.length == 1) {
        if (type == 'date') {
          this.selectCourtDate = true
        } else if (type == 'tag') {
          this.showEmphasisSign = true
        } else if (type == 'up') {
          this.showUploading = true
        } else if (type == 'end') {
          this.showFinishCase = true
        }
      } else if (this.myClientCheckboxArray.length < 1) {
        this._showMsg('请选择一个客户')
      } else {
        this._showMsg('仅能选择一个客户')
      }
    },
    // 选择的时间
    selectedDate(date) {
      this.courtDate = date
    },
    /**
     * @description: 设置开庭时间
     */
    setTime() {
      if (!this.courtDate) return this._showMsg('请选择时间', 'error')
      const param = {
        id: this.myClientCheckboxArray[0],
        startime: this.courtDate
      }
      caseApi.setStartime(param).then(res => {
        this.selectCourtDate = false
        this.getList()
        this._showMsg('设置成功', 'success')
      })
    },

    /**
     * 跳转页数
     */
    changePage(i) {
      this.listParam.page = i
      this.getList()
    },
    /**
     * @description: 上传方案
     * @param {*} v: 上传的列表
     */
    onSuccess(v) {
      this.uploadingFile = v
    },
    /**
     * @description: 确定上传方案
     */
    confirmUploading() {
      this.uploadingParam.order_id = this.myClientCheckboxArray[0]
      const files = []
      this.uploadingFile.forEach(item => {
        files.push(item.url.replace(config.baseUrl, ''))
      })
      this.uploadingParam.url = files.join(',')
      clientApi.addScheme(this.uploadingParam).then(res => {
        this._showMsg('上传方案成功', 'success')
        this.showUploading = false
      })
    }
  }
}
</script>
<style>
.el-button + .el-button,
.el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0;
}
</style>

<style lang="scss" scoped>
.follow-page {
  padding-bottom: 30px;
  background-color: #ffffff;
}

.classify-1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #ebf0f0;
}

.btn-blue {
  background: linear-gradient(109deg, #099cfa, #56b7f5);
}

.btn-green {
  background: linear-gradient(109deg, #2fca70, #05c154);
}

.btn-orange {
  background: linear-gradient(109deg, #fc9940, #f37902);
}

.btn-roseRed {
  background: linear-gradient(109deg, #fe626f, #fc394a);
}

.edit {
  color: #f6184c;
}

.end {
  color: #f47d09;
}
.active {
  border: none;
  color: #f5fafd;
  background: linear-gradient(109deg, #fc9940, #f37902);
}
.btn {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-bottom: 3.07rem;
  div {
    width: 7.78rem;
    height: 2.2rem;
    text-align: center;
    line-height: 2.2rem;
    border: 1px solid #eeeeee;
    cursor: pointer;
    background-color: #eeeeee;
    box-sizing: border-box;
    font-size: 0.94rem;
    color: #000000;
    font-weight: 400;
  }
  .follow-up {
    border-radius: 1rem 0rem 0rem 1rem;
  }

  .schedule {
    border-radius: 0rem 1rem 1rem 0rem;
  }
  .active {
    border: none;
    color: #f5fafd;
    background: linear-gradient(109deg, #fc9940, #f37902);
  }
}
.schedule-btn-case {
  display: flex;
  align-items: center;
  justify-content: center;
  .btn-edit,
  .btn-confirm {
    margin: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.83rem;
    width: 5.56rem;
    height: 1.91rem;
    border-radius: 0.78rem;
  }
  .btn-edit {
    color: #000000;
    background: #e5ebeb;
  }
  .btn-confirm {
    color: #ffffff;
    background: linear-gradient(215deg, #1db8ab, #179d92);
  }
}

.newClient {
  .title {
    width: 40rem;
    padding: 0 1.5rem;
    font-size: 0.94rem;
    font-weight: 400;
    color: #333333;
    line-height: 1;
  }

  .newClientFrom {
    padding: 1.55rem 0.5rem;
    display: flex;
    flex-wrap: wrap;
    .w-50 {
      display: flex;
      align-items: center;
      margin: 0.5rem 0;
      width: 50%;
    }
    .w-80 {
      display: flex;
      align-items: center;
      margin: 0.5rem 0;
      width: 80%;
    }
    .w1-80 {
      display: flex;
      align-items: flex-start;
      margin: 0.5rem 0;
      width: 95%;
      p {
        padding: 5px 0;
      }
      :deep() .el-textarea.is-disabled .el-textarea__inner {
        font-size: 0.84rem;
        font-weight: 400;
        color: #333333;
      }
    }
    .name {
      font-size: 0.94rem;
      flex-shrink: 0;
      text-align: right;
      width: 5.5rem;
      color: #333;
    }
    .inp {
      margin: 0 2rem 0 0.8rem;
      flex-grow: 1;
    }
    .align-start {
      align-items: flex-start;
    }
  }
}

.program {
  .programList {
    img {
      width: 4.11rem;
      height: 4.32rem;
    }
  }
}

.addTimeLine-box {
  padding: 6.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .addTimeLine {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5.56rem;
    height: 1.91rem;
    background: linear-gradient(215deg, #1db8ab, #179d92);
    border-radius: 0.63rem;
    font-size: 0.83rem;
    font-weight: 400;
    color: #ffffff;
    line-height: 1;
    cursor: pointer;
  }

  .btn-left {
    background: #e5ebeb;
    color: #000000;
    font-size: 0.83rem;
    border-radius: 0.78rem;
  }
  .m-left {
    margin-right: 2.19rem;
  }
}

.finishTask {
  display: flex;
  width: 24rem;

  p {
    width: 3.5rem;
  }
}

.selectDate {
  .inpTime {
    position: relative;
    margin-bottom: 2.5rem;
    img {
      width: 0.89rem;
      height: 0.94rem;
      position: absolute;
      top: 0.45rem;
      left: 1rem;
    }
    input {
      width: 10.16rem;
      height: 1.85rem;
      border: 1px solid #cccccc;
      border-radius: 0.52rem;
      padding: 0.45rem 0.78rem;
      padding-left: 2.5rem;
      font-size: 0.83rem;
    }
  }
}

.finishCase {
  padding: 2.66rem 4.51rem;
  text-align: center;
  font-size: 0.94rem;
  color: #333333;
  font-weight: 400;
  letter-spacing: 0.1rem;
}

.upRemark {
  display: flex;
  width: 24rem;
  margin-top: 1.88rem;
  margin-bottom: 2rem;
  span {
    width: 3.5rem;
  }
}
.flag {
  img {
    width: 0.89rem;
    height: 1.09rem;
  }
}

.schedule-case {
  width: 700px;
  display: flex;
  flex-wrap: wrap;
  .schedule-list {
    margin-bottom: 10px;
  }
}
</style>
